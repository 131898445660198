import AeditLink from 'services/link'
import { getDistance } from 'services/util/legacy'
import styles from '../provider_card.scss'
import useViewportTracker from 'hooks/use_viewport_tracker'
import { Track } from 'services/analytics'
import dynamic from 'next/dynamic'

const V2CardContent = dynamic(() => import('./card_content/v2_card_content.js'))
const BeuroCardContent = dynamic(() => import('./card_content/beuro_card_content.js'))
const BeuroRailCardContent = dynamic(() => import('./card_content/beuro_rail_card_content.js'))

const ProviderCardV2 = props => {
  const { provider, userLatLng, beuro, rail, onClick } = props
  const location = provider?.primary_practice?.location
  const CardContent = rail ? BeuroRailCardContent : beuro ? BeuroCardContent : V2CardContent

  const cardTracker = useViewportTracker(() => Track.providerCardSurfaced(provider.id))

  const providerLatLng =
    Boolean(location?.latitude) && Boolean(location?.longitude)
      ? {
          lat: Number(location.latitude),
          lng: Number(location.longitude),
        }
      : {}

  const photoCount = provider.procedure_before_and_afters_count || 0
  const distance = getDistance(userLatLng, providerLatLng, 'miles')
  const clickHandler = typeof onClick === 'function' ? { onClick } : {}

  return (
    <AeditLink
      as={`/provider/${provider.slug}`}
      pathname="/provider/[slug]"
      {...clickHandler}
      className={`provider-card-${rail ? 'rail' : beuro ? 'beuro' : 'v2'} d-flex mb-1`}>
      <CardContent
        provider={provider}
        location={location}
        cardTracker={cardTracker}
        providerLatLng={providerLatLng}
        photoCount={photoCount}
        distance={distance}
        clickHandler={clickHandler}
      />
      <style jsx>{styles}</style>
    </AeditLink>
  )
}

export default ProviderCardV2
