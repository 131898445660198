import React, { useState, useContext, useEffect } from 'react'
import cn from 'classnames'
import { GlobalContext } from 'components/global'
import { PriceCard } from '../price_card'

export const PlanSelect = ({ className = '', initialSelectedPlanId, handlePlanChange }) => {
  const { config } = useContext(GlobalContext)
  const subscriptionPlans = config?.subscriptions?.products
  const {
    all_access_concierge_monthly,
    all_access_concierge_semiannual,
    all_access_concierge_annual,
  } = subscriptionPlans

  const [selectedPlanId, setSelectedPlanId] = useState(
    initialSelectedPlanId ?? all_access_concierge_monthly?.id
  )

  useEffect(() => {
    // report plan changes to listener prop
    handlePlanChange?.(subscriptionPlans[selectedPlanId])
  }, [selectedPlanId])

  const handlePlanClick = id => () => setSelectedPlanId(id)

  const containerCn = cn('w-full grid grid-cols-3 gap-2.5', className)

  return (
    <section className={containerCn}>
      <PriceCard
        selected={selectedPlanId === all_access_concierge_monthly?.id}
        onClick={handlePlanClick(all_access_concierge_monthly?.id)}
        details={constructPlanDetails(all_access_concierge_monthly)}
      />
      <PriceCard
        selected={selectedPlanId === all_access_concierge_semiannual?.id}
        onClick={handlePlanClick(all_access_concierge_semiannual?.id)}
        details={constructPlanDetails(all_access_concierge_semiannual)}
      />
      <PriceCard
        selected={selectedPlanId === all_access_concierge_annual?.id}
        onClick={handlePlanClick(all_access_concierge_annual?.id)}
        details={constructPlanDetails(all_access_concierge_annual)}
      />
    </section>
  )
}

const constructPlanDetails = (plan = {}) => ({
  duration: plan.duration,
  price: `$${plan.amount}`,
  ...(plan.discount
    ? {
        heading: `Save ${plan.discount}`,
        subtext: `($${plan.costPerMonth}/mo)`,
      }
    : {
        heading: 'Popular',
        subtext: 'Flexible',
      }),
})
