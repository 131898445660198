import React, { useState, useContext, useEffect } from 'react'
import { BEURO__Btn } from 'styleguide/packages/components'
import { Portal, showPortal, createPortal, usePortal } from 'components/portal'
import { GlobalContext } from 'components/global'
import AeditLink from 'services/link'
import { HeadingCarousel } from './carousel'
import { BenefitsTable } from './benefits_table'
import { AeditLogo } from 'components/logos'
import { PlanSelect } from './plan_select'
import { ConsultationModal, PostAuthProTransitionModal, TransactionsModal } from 'components/modals'
import { Track } from 'services/analytics'
import useProSubscriptionStatus from 'hooks/use_pro_subscription_status'

/**
 * Pro paywall modal
 * @param {object} analytics - analytics data
 * @param {object} analytics.procedure - procedure for analytics purposes
 * @param {string} analytics.source - source from which the user triggered paywall - "vouchers" | undefined
 */
const ProPaywallModal = createPortal(({ analytics = {} }) => {
  const { toggleAuth, currentUser } = useContext(GlobalContext)
  const [selectedPlan, setSelectedPlan] = useState({})

  const userIsPro = useProSubscriptionStatus()

  const { source, procedure = {} } = analytics

  const modalControls = usePortal()
  const consultationControls = usePortal(ConsultationModal)

  useEffect(() => {
    Track.inAppPurchase.allAccessConcierge.loaded({ source })
  }, [])

  // close modal prior to page transition
  const handlePageLinkClick = () => {
    modalControls.remove()
    // kill in-progress consultation flow so we can transition pages
    if (Boolean(consultationControls.visible)) {
      return consultationControls.remove()
    }
  }

  const handleButtonClick = () => {
    // build analytics
    const procedureDetails =
      procedure.name && procedure.id
        ? { procedureName: procedure.name, procedureId: procedure.id }
        : {}

    const trackData = {
      productName: selectedPlan.id,
      cost: selectedPlan.amount,
      source,
      ...procedureDetails,
    }

    Track.inAppPurchase.allAccessConcierge.purchaseClicked(trackData)

    const authOptions = {
      stayOnCurrentPage: true,
      disableModalClosing: false,
      hideWelcome: true,
      loginCopyLabel: 'loginCopy.proPaywall',
      onAuthSuccess: () => showPortal(PostAuthProTransitionModal, { selectedPlan, analytics }),
    }

    modalControls.remove()

    // no authed user - toggle auth
    if (!currentUser) {
      return toggleAuth('login', authOptions)
    }

    // user hasn't completed registration -> show auth step 2
    if (currentUser && !Boolean(currentUser.completed_signup)) {
      return toggleAuth('register', authOptions)
    }

    // user authed but non-pro -> show transaction modal
    if (currentUser && !userIsPro) {
      return showPortal(TransactionsModal, { confirmedPlan: selectedPlan, analytics })
    }

    return showPortal(PostAuthProTransitionModal, { selectedPlan, analytics })
  }

  const handleClose = () => {
    Track.inAppPurchase.allAccessConcierge.dismissed()
    modalControls.remove()
  }

  return (
    <Portal.MobileFull
      hideIntercom
      className="animate-slide-up-from-bottom flex flex-col justify-center p-8 pro-paywall-modal"
      onClose={handleClose}>
      <AeditLogo size="medium" className="mx-auto mb-2" pro />
      <HeadingCarousel source={source} />
      <div className="md:max-w-sm w-full max-w-xs mx-auto">
        <BenefitsTable />
        <PlanSelect className="mb-6" handlePlanChange={setSelectedPlan} />
        <BEURO__Btn.Gold
          className="t-body-sm w-full font-semibold"
          content={
            <>
              Upgrade to <span className="t-overline text-sm font-semibold">PRO</span>
            </>
          }
          onClick={handleButtonClick}
        />
        <p className="t-caption text-dark-gray mb-2 text-center">*Free cancellations, anytime</p>
        {!currentUser && (
          <p className="t-body-sm mb-2 font-semibold text-center">
            Already a Pro member?
            <span role="button" className="text-gold-hover ml-1" onClick={handleButtonClick}>
              Log In
            </span>
          </p>
        )}

        <div className="text-dark-grey t-body-sm flex items-center justify-center w-full">
          <AeditLink
            onClick={handlePageLinkClick}
            pathname="/privacy-policy"
            className="mr-1 underline">
            Privacy Policy
          </AeditLink>
          <span>|</span>
          <AeditLink
            onClick={handlePageLinkClick}
            pathname="/terms-and-conditions"
            className="ml-1 underline">
            Terms of Use
          </AeditLink>
        </div>
      </div>
    </Portal.MobileFull>
  )
})

export const showProPaywallModal = analytics => {
  analytics = analytics ?? {}

  Track.gatedContentClicked(analytics)

  const paywallProps = { analytics }
  showPortal(ProPaywallModal, paywallProps)
}

export default ProPaywallModal
