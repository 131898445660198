import { PROVIDER_PROTECTED_ROUTES, PUBLIC_ROUTES, USER_PROTECTED_ROUTES } from 'services/constants'

const normalizePath = path => {
  // converts URLs such as '/user?abc=123#foo' into '/user/'
  const url = new URL(path ?? '/', process.env.WEB_HOST)
  const pathname = url.pathname
  return pathname.endsWith('/') ? pathname : `${pathname}/`
}

const buildRegex = path => {
  const normalized = normalizePath(path)
  return new RegExp(`^${normalized}.*$`, 'i')
}

const getRouteMatcher = currentPath => {
  currentPath = normalizePath(currentPath)
  return regex => regex.test(currentPath)
}

const PROVIDER_REGEX = PROVIDER_PROTECTED_ROUTES.map(buildRegex)
const USER_REGEX = USER_PROTECTED_ROUTES.map(buildRegex)
const PUBLIC_REGEX = PUBLIC_ROUTES.map(buildRegex) // override for otherwise protected routes

export const isProtectedRoute = currentPath => {
  return isUserProtectedRoute(currentPath) || isProviderProtectedRoute(currentPath)
}

export const isUserProtectedRoute = currentPath => {
  const matchRoute = getRouteMatcher(currentPath)

  if (PUBLIC_REGEX.some(matchRoute)) {
    return false
  }

  return USER_REGEX.some(matchRoute)
}

export const isProviderProtectedRoute = currentPath => {
  const matchRoute = getRouteMatcher(currentPath)

  if (PUBLIC_REGEX.some(matchRoute)) {
    return false
  }

  return PROVIDER_REGEX.some(matchRoute)
}

/**
 * Returns whether the user is authorized to view the page specified by param path
 */
export const isAuthorized = (path, currentUser) => {
  if (!currentUser) {
    // not authed and trying to hit a protected route
    if (isProtectedRoute(path)) {
      return false
    }
  } else {
    // authed and hits a protected route as the incorrect current user type
    if (
      (currentUser._type === 'provider' && isUserProtectedRoute(path)) ||
      (currentUser._type === 'user' && isProviderProtectedRoute(path))
    ) {
      return false
    }
  }

  return true
}
