import React, { useState } from 'react'
import classnames from 'classnames'
import useIntervalWhen from 'hooks/use_interval_when'
import { useSwipeable } from 'react-swipeable'
import { BEFORE_AND_AFTERS_SUBSCRIPTION_SOURCES } from 'services/util/payment'

const ITEMS = [
  {
    header: 'Save Money on Procedures',
    subheader:
      'Get up to $400 off procedure costs by booking with over 50 participating providers and guaranteed $0 booking fees.',
  },
  {
    header: 'Not Sure About a Procedure?',
    subheader:
      'Virtually try on medically accurate procedure results verified by a doable board certified plastic surgeon.',
  },
  {
    header: 'Expert Advice at Your Convenience',
    subheader:
      'Pro Members get access to a dedicated board certified plastic surgeon. Have a question? Unsure about a concern or a procedure? Just Ask.',
  },
  {
    header: 'Before & After Photos',
    subheader: 'Browse thousands of before & after photos from our elite community of top doctors.',
  },
]

export const HeadingCarousel = props => {
  const { source } = props

  const initialPanel = BEFORE_AND_AFTERS_SUBSCRIPTION_SOURCES.includes(source) ? 3 : 0
  const [panel, setPanel] = useState(initialPanel)

  const [freezeAutoCycle, setFreezeAutoCycle] = useState(false)
  const [reverseAnimation, setReverseAnimation] = useState(false)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      // go next panel
      setFreezeAutoCycle(true)
      if (reverseAnimation) setReverseAnimation(false)
      setPanel(curr => (curr + 1 > ITEMS.length - 1 ? 0 : curr + 1)) // next panel (or first if currently at last)
    },
    onSwipedRight: () => {
      // go prev panel
      setFreezeAutoCycle(true)
      if (!reverseAnimation) setReverseAnimation(true)
      setPanel(curr => (curr - 1 < 0 ? ITEMS.length - 1 : curr - 1)) // prev panel (or last if currently at first)
    },
    trackMouse: true,
    trackTouch: true,
  })

  // - cycles through the panels once and freezes on the first
  // - user selecting a panel or swiping also cancels the auto updater
  const panelAutoUpdateHandler = () =>
    setPanel(curr => {
      const next = curr + 1

      // reset to 0 and freeze after last panel
      if (next > ITEMS.length - 1) {
        setFreezeAutoCycle(true)
        return 0
      }

      return next
    })

  useIntervalWhen(panelAutoUpdateHandler, 5000, !freezeAutoCycle)

  const handlePanelSelect = panel => _ => {
    if (reverseAnimation) setReverseAnimation(false)

    setPanel(panel)
    setFreezeAutoCycle(true)
  }

  return (
    <>
      <section className="h-fit flex flex-col items-center w-full mt-1 mb-4 overflow-hidden">
        {ITEMS.map((item, idx) => (
          <Panel
            key={idx}
            swipeHandlers={swipeHandlers}
            reverseAnimation={reverseAnimation}
            header={item.header}
            subheader={item.subheader}
            active={panel === idx}
          />
        ))}

        <div className="flex items-center justify-between w-20">
          {ITEMS.map((item, idx) => (
            <SelectorPill key={idx} onClick={handlePanelSelect(idx)} active={panel === idx} />
          ))}
        </div>
      </section>
    </>
  )
}

const SelectorPill = ({ onClick, active }) => {
  return (
    <div
      className="flex items-center justify-center flex-1 h-4 mb-2 cursor-pointer"
      onClick={onClick}
      role="button">
      <div className={classnames('w-4 h-1 rounded', !active ? 'bg-[#d9d9d9]' : 'bg-gold')} />
    </div>
  )
}

const Panel = ({ header, subheader, active, reverseAnimation = false, swipeHandlers = {} }) => {
  if (!active) return null

  const animateCn = reverseAnimation ? 'animate-slide-in-left' : 'animate-slide-in-right'

  return (
    <div
      className={classnames(
        'touch-pan-y hover:cursor-grab active:cursor-grabbing select-none',
        animateCn
      )}
      {...swipeHandlers}>
      <h3 className="t-subsection-header w-full mb-2 text-center">{header}</h3>
      <p className="t-body-sm md:max-w-none md:px-0 w-full max-w-xs px-3 mb-2 text-center">
        {subheader}
      </p>
    </div>
  )
}
