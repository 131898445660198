import { useEffect, useRef } from 'react'

const useIntervalWhen = (callback = () => {}, interval = 300, when = true, immediate = false) => {
  const savedCallbackRef = useRef()

  useEffect(() => {
    savedCallbackRef.current = callback
  }, [callback])

  function cb() {
    savedCallbackRef.current && savedCallbackRef.current()
  }

  useEffect(() => {
    if (when) {
      if (immediate) {
        cb()
      }

      const id = setInterval(cb, interval)

      return () => clearInterval(id)
    }
  }, [when, interval])
}

export default useIntervalWhen
