import { useContext } from 'react'
import { GlobalContext } from 'components/global'
import { hasProSubscription } from 'services/util/user'

const useProSubscriptionStatus = () => {
  const { currentUser, config } = useContext(GlobalContext)

  return hasProSubscription(currentUser, config)
}

export default useProSubscriptionStatus
