import React from 'react'
import cn from 'classnames'

const DropdownMenu = ({
  children,
  className = 'absolute top-full bg-white',
  isShown = false,
  setClosed,
  clickToExit,
  ...rest
}) => {
  return (
    <>
      <div {...rest} className={cn(className, { block: isShown, hidden: !isShown })}>
        {children}
      </div>

      <div
        className={cn(
          'fixed right-0 left-0 top-0 bottom-0 bg-legacy-real_black bg-opacity-30 -z-10',
          {
            block: isShown,
            hidden: !isShown,
          }
        )}
        onMouseEnter={setClosed}
      />
    </>
  )
}

DropdownMenu.Box = ({ children, className = '' }) => (
  <div className={`w-full ${className}`}>{children}</div>
)

export default DropdownMenu
