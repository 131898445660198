import React from 'react'
import cn from 'classnames'

export const PriceCard = ({ details = {}, selected = false, onClick = () => {} }) => {
  const { duration, heading, price, subtext } = details

  const durationText = duration > 1 ? 'months' : 'month'

  const containerCn = cn(
    'bg-ivory rounded-lg border cursor-pointer overflow-hidden',
    selected ? 'border-gold' : 'border-white'
  )
  const headingTextCn = cn(
    't-caption flex items-center justify-center py-[0.2rem]',
    selected ? 'bg-gold text-black font-semibold' : 'bg-ivory-hover text-[#464646]'
  )
  const durationTextCn = cn('t-body-sm', selected ? 'text-[#464646]' : 'text-black')
  const priceTextCn = cn(
    't-subsection-header leading-tight',
    selected ? 'text-[#464646]' : 'text-black'
  )
  const subtextCn = cn('t-caption', selected ? 'text-link' : 'text-dark-grey')

  return (
    <div role="button" onClick={onClick} className={containerCn}>
      <h5 className={headingTextCn}>{heading}</h5>
      <div className="flex flex-col items-center justify-center py-2 overflow-hidden">
        <div className={durationTextCn}>
          {duration} {durationText}
        </div>
        <div className={priceTextCn}>{price}</div>
        <div className={subtextCn}>{subtext}</div>
      </div>
    </div>
  )
}
