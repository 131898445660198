/**
 * Returns true if the user has an active PRO subscription.
 *
 * It will always return false if the backend config (with pro products) is not provided
 *
 * @param {Object} user
 * @param {Object} config
 * @param {?Object=} params
 * @param {?boolean=} params.inactive Whether you want to look for a PRO subscription in
 *  inactive subscriptions as well. Defaults to false.
 * @param {?('stripe'|'apple')=} params.source External source of the subscription
 * @return {boolean}
 */
export const hasProSubscription = (user, config, params = null) => {
  if (!user) return false

  const { subscriptions = null } = user || {}
  params = params || { inactive: false, source: null }

  if (!subscriptions || !Array.isArray(subscriptions) || !Boolean(subscriptions.length)) {
    return false
  }

  const validProducts = config?.subscriptions?.products ?? {}

  const proProducts = Object.values(validProducts).map(product => product.id)

  return subscriptions.some(sub => {
    const { is_active, external_source, product = {} } = sub

    if (!proProducts.includes(product.id)) {
      return false
    }

    if (params?.source && params?.source !== external_source) {
      return false
    }

    if (params?.inactive) {
      return true
    }

    return Boolean(parseInt(is_active, 10))
  })
}
