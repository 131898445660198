import React from 'react'

export const BenefitsTable = () => {
  return (
    <div className="benefits-table-container flex flex-col w-full mb-4">
      <div className="flex-nowrap flex w-full py-2">
        <span className="flex flex-1 ml-3" />
        <span className="t-overline-sm flex items-center justify-center flex-1 font-semibold">
          non-pro
        </span>
        <span className="t-overline-sm flex items-center justify-center flex-1 font-semibold">
          pro member
        </span>
      </div>
      <div className="flex-nowrap bg-ivory flex w-full py-2 rounded-lg">
        <span className="t-caption flex items-center flex-1 pl-3 font-semibold">3D Try-Ons</span>
        <span className="t-caption flex items-center justify-center flex-1">Basic</span>
        <span className="t-caption text-gold-hover flex items-center justify-center flex-1">
          Premium
        </span>
      </div>
      <div className="flex-nowrap flex w-full py-2">
        <span className="t-caption flex items-center flex-1 pl-3 font-semibold">
          Procedure discount
        </span>
        <span className="t-caption flex items-center justify-center flex-1">None</span>
        <span className="t-caption text-gold-hover flex items-center justify-center flex-1">
          $50-$400
        </span>
      </div>
      <div className="flex-nowrap bg-ivory flex w-full py-2 rounded-lg">
        <span className="t-caption flex items-center flex-1 pl-3 font-semibold">
          Before & after photos
        </span>
        <span className="t-caption flex items-center justify-center flex-1">None</span>
        <span className="t-caption text-gold-hover flex items-center justify-center flex-1">
          Unlimited
        </span>
      </div>
      <div className="flex-nowrap flex w-full py-2">
        <span className="t-caption flex items-center flex-1 pl-3 font-semibold">
          On-demand Doctor
        </span>
        <span className="t-caption flex items-center justify-center flex-1">None</span>
        <span className="t-caption text-gold-hover flex items-center justify-center flex-1">
          Premium
        </span>
      </div>
    </div>
  )
}
